import React, { createRef } from 'react'
import classNamesBind from 'classnames/bind'
import { debounce } from 'debounce'
import css from './styles.module.scss'
const classNames = classNamesBind.bind(css)

const DEFAULT_DIRECTION: OverlayDirection = 'topRight'

export type OverlayDirection =
  | 'topRight'
  | 'topLeft'
  | 'bottomRight'
  | 'bottomLeft'
  | 'bottom'
  | 'top'

interface OwnProps {
  overlayContent: React.ReactNode
  preferredDirection?: OverlayDirection
  withTransform?: boolean
  overlayGap?: number
  noHover?: boolean
  flex?: boolean
  fullWidth?: boolean
  containerStyles?: React.CSSProperties
  customTop?: number
  children: React.ReactNode
}

interface State {
  top: number
  left: number
}

export default class ReactiveOverlay extends React.Component<OwnProps, State> {
  containerRef = createRef<HTMLDivElement>()
  overlayRef = createRef<HTMLDivElement>()
  handleResize: () => void

  constructor(props: OwnProps) {
    super(props)

    this.handleResize = debounce(this.updatePosition, 150)

    this.state = {
      top: 0,
      left: 0,
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)

    this.updatePosition()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  render() {
    const { children, containerStyles, noHover, flex, fullWidth } = this.props
    const style: React.CSSProperties = containerStyles || {}

    return (
      <div
        className={classNames('container', {
          noHover,
          hover: !noHover,
          flex,
          fullWidth,
        })}
        ref={this.containerRef}
        style={style}
      >
        {children}
        {this.renderOverlay()}
      </div>
    )
  }

  // Partials
  renderOverlay() {
    const { overlayContent, withTransform, noHover } = this.props

    return (
      <div
        style={this.overlayStyles()}
        className={classNames(
          'overlay',
          { withTransform, noHover },
          this.renderedDirection(),
        )}
        ref={this.overlayRef}
      >
        {overlayContent}
      </div>
    )
  }

  // Functions
  overlayDirection(): OverlayDirection {
    const { preferredDirection } = this.props

    return preferredDirection || DEFAULT_DIRECTION
  }

  overlayStyles(): React.CSSProperties {
    const { top: stateTop, left } = this.state
    const { customTop } = this.props
    const top = customTop ? customTop : stateTop

    return {
      top: `${top}px`,
      left: `${left}px`,
    }
  }

  calculateTop(
    containerRect: DOMRect,
    overlayRect: DOMRect,
    direction: OverlayDirection,
  ): number {
    let relativeTop = 0
    const overlayHeight = overlayRect.height * 1.2

    switch (direction) {
      case 'topRight':
      case 'topLeft':
      case 'top': {
        relativeTop = -overlayHeight - this.overlayGap()

        if (containerRect.top - overlayHeight - this.overlayGap() < 0) {
          relativeTop = containerRect.height
        }

        break
      }
      case 'bottomRight':
      case 'bottomLeft':
      case 'bottom': {
        relativeTop = containerRect.height + this.overlayGap()

        if (
          containerRect.bottom + overlayHeight + this.overlayGap() >
          document.body.scrollHeight
        ) {
          relativeTop = -overlayHeight
        }

        break
      }
    }

    return relativeTop
  }

  calculateLeft(
    containerRect: DOMRect,
    overlayRect: DOMRect,
    direction: OverlayDirection,
  ): number {
    let relativeLeft = 0
    const overlayWidth = overlayRect.width * 1.2

    switch (direction) {
      case 'topRight':
      case 'bottomRight': {
        relativeLeft = containerRect.width + this.overlayGap()
        const absoluteRight =
          containerRect.right + overlayWidth + this.overlayGap()

        if (absoluteRight > window.innerWidth) {
          relativeLeft -= absoluteRight - window.innerWidth + 32
        }

        break
      }
      case 'topLeft':
      case 'bottomLeft': {
        relativeLeft = -overlayWidth - this.overlayGap()
        const absoluteLeft = containerRect.left - overlayWidth

        if (absoluteLeft < 0) {
          relativeLeft += -absoluteLeft + 32
        }

        break
      }
      case 'bottom':
      case 'top': {
        relativeLeft = containerRect.width / 2 - overlayRect.width / 2
        break
      }
    }

    return relativeLeft
  }

  updatePosition = () => {
    const containerEl = this.containerRef.current
    const overlayEl = this.overlayRef.current
    const direction = this.overlayDirection()

    if (!overlayEl || !containerEl) return

    const containerRect = containerEl.getBoundingClientRect()
    const overlayRect = overlayEl.getBoundingClientRect()

    this.setState({
      top: this.calculateTop(containerRect, overlayRect, direction),
      left: this.calculateLeft(containerRect, overlayRect, direction),
    })
  }

  renderedDirection(): OverlayDirection {
    const { top, left } = this.state
    const element = this.containerRef.current
    const isTopOrBottom = ['top', 'bottom'].includes(this.overlayDirection())

    if (!element) return DEFAULT_DIRECTION

    if (top < 0) {
      if (isTopOrBottom) return 'top'
      else if (left < 0) return 'topLeft'
      else return 'topRight'
    } else {
      if (isTopOrBottom) return 'bottom'
      else if (left < 0) return 'bottomLeft'
      else return 'bottomRight'
    }
  }

  overlayGap() {
    const { overlayGap } = this.props

    return overlayGap ?? 0
  }
}
