function capitalize(str: string) {
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`
}

export function tsErrorHelper(value: never) {
  value
}

function formatName(name: string) {
  return name.split('_').map(capitalize).join(' ')
}

function individualModuleNames() {
  return [
    'disc_type',
    'enneagram',
    'myers_briggs',
    'strengths',
    'values',
    'what_comes_naturally',
    'energizes',
    'drains',
    'potential_strengths',
    'potential_blind_spots',
    'how_to_speak',
    'how_to_email',
    'how_to_meet',
    'how_to_collaborate',
    'how_to_persuade',
    'how_to_negotiate',
    'how_to_give_feedback',
    'working_with_a_boss',
    'working_with_peers',
    'working_with_direct_reports',
    'ideal_job_responsibilities',
    'responding_to_stress',
    'potential_careers',
    'be_more_productive',
    'communicate_more_effectively',
    'negotiate_more_effectively',
    'resolve_conflicts',
    'lead_more_effectively',
    'introduction_to_personality',
    'job_title_comparison',
    'providing_support',
    'gather_information',
    'making_decisions',
    'setting_expectations',
    'individual_working_remotely',
    'individual_schedule_meeting',
    'individual_sales_pitch',
    'individual_discuss_pricing',
    'individual_sales_demo',
    'individual_thank_you',
    'individual_follow_up',
    'individual_say_no',
    'individual_phone_call',
    'individual_apologize',
    'individual_introduce',
    'individual_compensation',
    'individual_performance',
    'individual_training_discuss_pricing',
    'individual_drive_decisions',
    'individual_gather_information',
    'individual_product_demo',
    'individual_provide_support',
    'individual_get_advice',
    'individual_invite_to_event',
    'individual_give_gift',
    'individual_encourage',
  ] as const
}

function relationshipModuleNames() {
  return [
    'disc_comparison',
    'enneagram_comparison',
    'myers_briggs_comparison',
    'strengths_comparison',
    'values_comparison',
    'trait_comparison',
    'relationship_overview',
    'communicating_effectively',
    'encouraging_and_motivating',
    'emailing_effectively',
    'giving_instructions',
    'managing_stress',
    'meeting_effectively',
    'negotiating_effectively',
    'navigating_change',
    'persuasion_and_influence',
    'resolving_conflict',
    'working_together',
    'relationship_dynamics',
  ] as const
}
function groupModuleNames() {
  return [
    'personality_types',
    'personality_map',
    'group_strengths',
    'group_weaknesses',
    'personality_fit',
    'give_feedback',
    'resolve_conflict',
    'brainstorm_ideas',
    'collaborate_on_a_project',
    'make_a_sales_pitch',
    'make_a_good_impression',
    'persuade_them_to_take_action',
    'schedule_a_meeting',
    'negotiate',
    'discuss_pricing',
    'deliver_bad_news',
    'give_instructions',
    'ask_for_a_raise',
    'convince_them_to_hire_you',
    'group_trait_meter',
    'personality_matrix',
    'natural_roles',
    'natural_roles_making_decisions',
    'natural_roles_resolve_conflict',
    'natural_roles_email_style',
    'natural_roles_negotiation',
    'natural_roles_brainstorm',
    'group_overview',
    'team_brainstorm',
    'team_resolve_conflict',
    'team_negotiations',
    'team_email_style',
    'team_making_decisions',
    'working_remotely',
    'group_making_decisions',
    'group_providing_support',
    'group_gathering_information',
    'group_setting_expectations',
    'group_thank_you',
    'group_follow_up',
    'group_say_no',
    'group_communication',
    'group_email',
    'group_motivation',
    'group_apologize',
    'group_introduce',
    'group_encourage',
  ] as const
}
function jobModuleNames() {
  return [
    'job_disc_type',
    'job_blind_spots',
    'job_what_comes_naturally',
    'job_strengths',
    'job_communicate_more_effectively',
    'job_making_decisions',
  ] as const
}
function jobProfileModuleNames() {
  return [
    'job_disc_comparison',
    'job_profile_trait_comparison',
    'job_potential_energizers',
    'job_potential_stressors',
    'job_interview_questions',
    'job_how_to_interview',
    'job_profile_how_to_speak',
    'job_profile_how_to_meet',
    'job_profile_how_to_email',
    'job_profile_phone_call',
    'job_profile_how_to_persuade',
    'job_profile_how_to_negotiate',
    'job_profile_schedule_meeting',
    'job_profile_behavioral_gap',
    'job_profile_relationship_preview',
    'job_candidate_scores',
    'job_profile_potential_strengths',
    'job_profile_potential_blind_spots',
  ] as const
}
function jobProfilesModuleNames() {
  return [
    'job_profiles_personality_map',
    'job_profiles_personality_traits',
    'job_profiles_personality_breakdown',
    'job_negotiating_style',
    'job_collaborate_on_team',
  ] as const
}
function jobsProfileModuleNames() {
  return [
    'jobs_profile_personality_map',
    'jobs_profile_personality_traits',
    'jobs_profile_job_fit_breakdown',
    'job_ideal_job_responsibilities',
    'job_working_remotely',
  ] as const
}

const individualNames = individualModuleNames()
const relationshipNames = relationshipModuleNames()
const groupNames = groupModuleNames()
const jobNames = jobModuleNames()
const jobProfileNames = jobProfileModuleNames()
const jobProfilesNames = jobProfilesModuleNames()
const jobsProfileNames = jobsProfileModuleNames()

export type IndividualPlaybookModuleNames = (typeof individualNames)[number]
export type RelationshipPlaybookModuleNames = (typeof relationshipNames)[number]
export type GroupPlaybookModuleNames = (typeof groupNames)[number]
export type JobPlaybookModuleNames = (typeof jobNames)[number]
export type JobProfilePlaybookModuleNames = (typeof jobProfileNames)[number]
export type JobProfilesPlaybookModuleNames = (typeof jobProfilesNames)[number]
export type JobsProfilePlaybookModuleNames = (typeof jobsProfileNames)[number]
export type PlaybookModuleTypes =
  | IndividualPlaybookModuleNames
  | RelationshipPlaybookModuleNames
  | GroupPlaybookModuleNames
  | JobPlaybookModuleNames
  | JobProfilePlaybookModuleNames
  | JobProfilesPlaybookModuleNames
  | JobsProfilePlaybookModuleNames

export function getStructureDescription(name: PlaybookModuleTypes): string {
  switch (name) {
    // Individual
    case 'disc_type':
      return 'DISC'
    case 'enneagram':
      return 'Enneagram'
    case 'myers_briggs':
      return '16-Personality'
    case 'strengths':
      return 'Strengths'
    case 'values':
      return 'Values'
    case 'what_comes_naturally':
      return 'Natural Tendencies'
    case 'energizes':
      return 'Energizers'
    case 'drains':
      return 'Stressors'
    case 'potential_strengths':
      return 'Strengths'
    case 'gather_information':
      return 'Gather Information'
    case 'potential_blind_spots':
      return 'Blind Spots'
    case 'how_to_speak':
      return 'Speaking Advice'
    case 'how_to_email':
      return 'Emailing Advice'
    case 'how_to_meet':
      return 'Meeting Advice'
    case 'how_to_collaborate':
      return 'Collaboration Advice'
    case 'how_to_persuade':
      return 'Persuasion Advice'
    case 'how_to_negotiate':
      return 'Negotiation Advice'
    case 'how_to_give_feedback':
      return 'Giving Feedback Advice'
    case 'working_with_a_boss':
      return 'How first_name works with a boss'
    case 'working_with_peers':
      return 'Working with first_name'
    case 'working_with_direct_reports':
      return 'How first_name Manages'
    case 'ideal_job_responsibilities':
      return 'Ideal Job Responsibilites'
    case 'responding_to_stress':
      return 'Responding to Stress'
    case 'potential_careers':
      return 'Potential Careers'
    case 'be_more_productive':
      return 'Productivity Tips'
    case 'communicate_more_effectively':
      return 'Communication Tips'
    case 'negotiate_more_effectively':
      return 'Negotiation Tips'
    case 'resolve_conflicts':
      return 'Resolving Conflict Tips'
    case 'lead_more_effectively':
      return 'Leadership Tips'
    case 'introduction_to_personality':
      return 'Personality Overview'
    case 'job_title_comparison':
      return 'Job Title Comparison'
    case 'individual_working_remotely':
      return 'Working Remotely'
    case 'individual_compensation':
      return 'Discuss Compensation'
    case 'individual_performance':
      return 'Performance Review'
    case 'individual_training_discuss_pricing':
      return 'Discuss Pricing'
    case 'individual_drive_decisions':
      return 'Drive Decisions'
    case 'individual_gather_information':
      return 'Gather Information'
    case 'individual_product_demo':
      return 'Give a Product Demo'
    case 'individual_provide_support':
      return 'Provide Support'
    case 'individual_get_advice':
      return 'Get Advice'
    case 'individual_invite_to_event':
      return 'Invite To Event'
    case 'individual_give_gift':
      return 'Give Gift'
    case 'individual_encourage':
      return 'How to Encourage'

    // Relationship
    case 'disc_comparison':
      return 'DISC Assessment'
    case 'enneagram_comparison':
      return 'Enneagram Assessment'
    case 'myers_briggs_comparison':
      return '16-Personality Assessment'
    case 'strengths_comparison':
      return 'Strengths Assessment'
    case 'values_comparison':
      return 'Values Assessment'
    case 'trait_comparison':
      return 'Trait Comparison'
    case 'relationship_overview':
      return 'Relationship Overview'
    case 'communicating_effectively':
      return 'Communicating Effectively'
    case 'encouraging_and_motivating':
      return 'Motivating'
    case 'emailing_effectively':
      return 'Emailing Effectively'
    case 'giving_instructions':
      return 'Giving Instructions'
    case 'managing_stress':
      return 'Managing Stress'
    case 'meeting_effectively':
      return 'Meeting Effectively'
    case 'negotiating_effectively':
      return 'Negotiating Effectively'
    case 'navigating_change':
      return 'Navigating Change'
    case 'persuasion_and_influence':
      return 'Persuasion and Influence'
    case 'resolving_conflict':
      return 'Resolving Conflict'
    case 'working_together':
      return 'Working Together'
    case 'relationship_dynamics':
      return 'Relationship Dynamics'
    case 'individual_apologize':
      return 'Apologize'
    case 'individual_introduce':
      return 'Introduce'

    // Group
    case 'personality_types':
      return 'Personality Types'
    case 'personality_map':
      return 'Personality Map'
    case 'group_overview':
      return 'Group Dynamics'
    case 'group_strengths':
      return 'Group Strengths'
    case 'group_weaknesses':
      return 'Group Weaknesses'
    case 'personality_fit':
      return 'Personality Fit'
    case 'give_feedback':
      return 'Give Feedback'
    case 'resolve_conflict':
      return 'Resolve Conflict'
    case 'brainstorm_ideas':
      return 'Brainstorm Ideas'
    case 'collaborate_on_a_project':
      return 'Collaborate on a project'
    case 'make_a_sales_pitch':
      return 'Make a sales pitch'
    case 'make_a_good_impression':
      return 'Make a good impression'
    case 'persuade_them_to_take_action':
      return 'Persuade to take action'
    case 'schedule_a_meeting':
      return 'Schedule a meeting'
    case 'negotiate':
      return 'Negotiate'
    case 'discuss_pricing':
      return 'Discuss pricing'
    case 'deliver_bad_news':
      return 'Deliver bad news'
    case 'give_instructions':
      return 'Give instructions'
    case 'ask_for_a_raise':
      return 'Ask for a raise'
    case 'convince_them_to_hire_you':
      return 'Convince them to hire you'
    case 'group_trait_meter':
      return 'Trait Comparison'
    case 'personality_matrix':
      return 'Relationship Matrix'
    case 'natural_roles':
      return 'Meeting Style'
    case 'natural_roles_making_decisions':
      return 'Decision-Making Style'
    case 'natural_roles_resolve_conflict':
      return 'Conflict Style'
    case 'natural_roles_email_style':
      return 'Email Style'
    case 'natural_roles_negotiation':
      return 'Negotiating Style'
    case 'natural_roles_brainstorm':
      return 'Brainstorming Style'
    case 'providing_support':
      return 'Providing Support'
    case 'making_decisions':
      return 'Making Decisions'
    case 'setting_expectations':
      return 'Setting Expectations'
    case 'team_brainstorm':
      return 'Group Brainstorm'
    case 'team_resolve_conflict':
      return 'Group Conflict'
    case 'team_negotiations':
      return 'Group Negotiation'
    case 'team_email_style':
      return 'Group Email'
    case 'team_making_decisions':
      return 'Group Decision Making'
    case 'working_remotely':
      return 'Working Remotely'
    case 'group_making_decisions':
      return 'Help Make Decisions'
    case 'group_providing_support':
      return 'Provide Support'
    case 'group_gathering_information':
      return 'Gather Information'
    case 'group_setting_expectations':
      return 'Set Expectations'
    case 'individual_schedule_meeting':
      return 'Schedule Meeting'
    case 'individual_sales_pitch':
      return 'Sales Pitch'
    case 'individual_discuss_pricing':
      return 'Discuss Pricing'
    case 'individual_sales_demo':
      return 'Sales Demo'

    // Job
    case 'job_disc_type':
      return 'Disc Type'
    case 'job_blind_spots':
      return 'Blind Spots'
    case 'job_what_comes_naturally':
      return 'What Comes Naturally'
    case 'job_strengths':
      return 'Strengths'
    case 'job_communicate_more_effectively':
      return 'Communicate More Effectively'
    case 'job_making_decisions':
      return 'Making Decisions'
    case 'job_disc_comparison':
      return 'Disc Comparison'

    // Job Profile
    case 'job_profile_trait_comparison':
      return 'Trait Comparison'
    case 'job_potential_energizers':
      return 'Potential Energizers'
    case 'job_potential_stressors':
      return 'Potential Stressors'
    case 'job_interview_questions':
      return 'Interview Questions'
    case 'job_profile_how_to_speak':
      return 'Speaking Advice'
    case 'job_profile_how_to_meet':
      return 'Meeting Advice'
    case 'job_profile_how_to_email':
      return 'Emailing Advice'
    case 'job_profile_phone_call':
      return 'Phone Call'
    case 'job_profile_how_to_persuade':
      return 'Persuasion Advice'
    case 'job_profile_how_to_negotiate':
      return 'Negotiation Advice'
    case 'job_profile_schedule_meeting':
      return 'Schedule Meeting'
    case 'job_profile_behavioral_gap':
      return 'Behavioral Gap'
    case 'job_profile_relationship_preview':
      return 'Relationship Preview'
    case 'job_profile_potential_strengths':
      return 'Strengths'
    case 'job_profile_potential_blind_spots':
      return 'Blind Spots'
    case 'job_candidate_scores':
      return 'Candidate Scores'

    // Job Profiles
    case 'job_profiles_personality_map':
      return 'Personality Map'
    case 'job_profiles_personality_traits':
      return 'Personality Traits'
    case 'job_profiles_personality_breakdown':
      return 'Personality Breakdown'

    // Jobs Profile
    case 'jobs_profile_personality_map':
      return 'Personality Map'
    case 'jobs_profile_personality_traits':
      return 'Personality Traits'
    case 'jobs_profile_job_fit_breakdown':
      return 'Job Fit Breakdown'
    case 'job_negotiating_style':
      return 'Negotiating Style'
    case 'job_collaborate_on_team':
      return 'Communicating on a Project'
    case 'job_how_to_interview':
      return 'How to Interview'
    case 'job_ideal_job_responsibilities':
      return 'Ideal Job Responsibilities'
    case 'job_working_remotely':
      return 'Working Remotely'

    // Individual
    case 'individual_thank_you':
      return 'Say Thank You'
    case 'individual_follow_up':
      return 'Follow Up'
    case 'individual_say_no':
      return 'Say No'
    case 'individual_phone_call':
      return 'Phone Call'

    // Group
    case 'group_thank_you':
      return 'Thank You'
    case 'group_follow_up':
      return 'Follow Up'
    case 'group_say_no':
      return 'Say No'
    case 'group_communication':
      return 'Group Communication Style'
    case 'group_email':
      return 'Group Email Style'
    case 'group_motivation':
      return 'Group Motivations'
    case 'group_apologize':
      return 'Apologize'
    case 'group_introduce':
      return 'Introduce'
    case 'group_encourage':
      return 'How to Encourage'
    default: {
      tsErrorHelper(name)
      return formatName(name)
    }
  }
}

export function getStructureCategory(name: PlaybookModuleTypes): string {
  switch (name) {
    // Individual
    case 'disc_type':
    case 'enneagram':
    case 'myers_briggs':
    case 'strengths':
    case 'values':
      return 'assessments'
    case 'what_comes_naturally':
    case 'energizes':
    case 'drains':
    case 'potential_strengths':
    case 'potential_blind_spots':
    case 'how_to_speak':
    case 'how_to_email':
    case 'how_to_meet':
    case 'how_to_collaborate':
    case 'how_to_persuade':
    case 'how_to_negotiate':
    case 'how_to_give_feedback':
    case 'individual_schedule_meeting':
    case 'individual_sales_pitch':
    case 'individual_discuss_pricing':
    case 'individual_sales_demo':
    case 'individual_thank_you':
    case 'individual_follow_up':
    case 'individual_say_no':
    case 'individual_phone_call':
    case 'individual_apologize':
    case 'individual_introduce':
    case 'individual_compensation':
    case 'individual_performance':
    case 'individual_get_advice':
    case 'individual_invite_to_event':
    case 'individual_give_gift':
    case 'individual_encourage':
      return 'insights'
    case 'individual_training_discuss_pricing':
    case 'individual_drive_decisions':
    case 'individual_gather_information':
    case 'individual_product_demo':
    case 'individual_provide_support':
      return 'self improvement'
    case 'individual_working_remotely':
    case 'working_with_a_boss':
    case 'working_with_peers':
    case 'working_with_direct_reports':
    case 'ideal_job_responsibilities':
    case 'responding_to_stress':
    case 'potential_careers':
    case 'be_more_productive':
    case 'communicate_more_effectively':
    case 'negotiate_more_effectively':
    case 'resolve_conflicts':
    case 'lead_more_effectively':
    case 'introduction_to_personality':
    case 'job_title_comparison':
      return 'work_style'

    // Relationship
    case 'disc_comparison':
    case 'enneagram_comparison':
    case 'myers_briggs_comparison':
    case 'strengths_comparison':
    case 'values_comparison':
    case 'trait_comparison':
      return 'assessments'
    case 'relationship_overview':
    case 'communicating_effectively':
    case 'encouraging_and_motivating':
    case 'emailing_effectively':
    case 'giving_instructions':
    case 'managing_stress':
    case 'meeting_effectively':
    case 'negotiating_effectively':
    case 'navigating_change':
    case 'persuasion_and_influence':
    case 'resolving_conflict':
    case 'working_together':
    case 'relationship_dynamics':
    case 'providing_support':
    case 'gather_information':
    case 'making_decisions':
    case 'setting_expectations':
      return 'insights'

    // Group
    case 'personality_map':
    case 'group_overview':
    case 'team_brainstorm':
    case 'team_resolve_conflict':
    case 'team_negotiations':
    case 'team_email_style':
    case 'team_making_decisions':
      return 'overviews'
    case 'personality_types':
    case 'natural_roles':
    case 'natural_roles_making_decisions':
    case 'natural_roles_resolve_conflict':
    case 'natural_roles_email_style':
    case 'natural_roles_negotiation':
    case 'natural_roles_brainstorm':
      return 'breakdowns'
    case 'group_trait_meter':
    case 'group_strengths':
    case 'group_weaknesses':
    case 'personality_fit':
    case 'personality_matrix':
    case 'group_communication':
    case 'group_email':
    case 'group_motivation':
      return 'analysis'
    case 'give_feedback':
    case 'resolve_conflict':
    case 'brainstorm_ideas':
    case 'collaborate_on_a_project':
    case 'make_a_sales_pitch':
    case 'make_a_good_impression':
    case 'persuade_them_to_take_action':
    case 'schedule_a_meeting':
    case 'negotiate':
    case 'discuss_pricing':
    case 'deliver_bad_news':
    case 'give_instructions':
    case 'ask_for_a_raise':
    case 'convince_them_to_hire_you':
    case 'working_remotely':
    case 'group_making_decisions':
    case 'group_providing_support':
    case 'group_gathering_information':
    case 'group_setting_expectations':
    case 'group_thank_you':
    case 'group_follow_up':
    case 'group_say_no':
    case 'group_apologize':
    case 'group_introduce':
    case 'group_encourage':
      return 'advice'

    // Job
    case 'job_disc_type':
    case 'job_blind_spots':
    case 'job_what_comes_naturally':
    case 'job_strengths':
    case 'job_communicate_more_effectively':
    case 'job_making_decisions':
      return 'Job Insights'

    // Job Profile
    case 'job_disc_comparison':
    case 'job_profile_trait_comparison':
    case 'job_potential_energizers':
    case 'job_potential_stressors':
    case 'job_interview_questions':
    case 'job_profile_how_to_speak':
    case 'job_profile_how_to_meet':
    case 'job_profile_how_to_email':
    case 'job_profile_phone_call':
    case 'job_profile_how_to_persuade':
    case 'job_profile_how_to_negotiate':
    case 'job_profile_schedule_meeting':
    case 'job_profile_behavioral_gap':
    case 'job_profile_relationship_preview':
    case 'job_profile_potential_strengths':
    case 'job_profile_potential_blind_spots':
    case 'job_candidate_scores':
      return 'Job Profile'

    // Job Profiles
    case 'job_profiles_personality_map':
    case 'job_profiles_personality_traits':
    case 'job_profiles_personality_breakdown':
    case 'job_negotiating_style':
    case 'job_collaborate_on_team':
    case 'job_how_to_interview':
      return 'Job Profiles'

    // Jobs Profile
    case 'jobs_profile_personality_map':
    case 'jobs_profile_personality_traits':
    case 'jobs_profile_job_fit_breakdown':
    case 'job_ideal_job_responsibilities':
    case 'job_working_remotely':
      return 'Jobs Profile'

    default: {
      tsErrorHelper(name)
      return formatName(name)
    }
  }
}
